var acc = {};
var mag = {};
/* Accelerometer  */
if (window.DeviceMotionEvent) {
  window.addEventListener("devicemotion", motion, false);
} else {
  console.log("DeviceMotionEvent is not supported");
}

function motion(event) {
  acc = {
    x: event.accelerationIncludingGravity.x,
    y: event.accelerationIncludingGravity.y,
    z: event.accelerationIncludingGravity.z
  };
}

/* Magnetometer */
if (window.DeviceOrientationEvent) {
  window.addEventListener("deviceorientation", orientation, false);
} else {
  console.log("DeviceOrientationEvent is not supported");
}

function orientation(event) {
  mag = {
    a: event.alpha,
    b: event.beta,
    g: event.gamma
  };
}

export { acc, mag };
