import React, { Component } from "react";

class ParallaxBackDrop extends Component {
  render() {
    let l = 0;
    if (this.props.x !== null && this.props.x !== undefined) {
      let x = Math.round(parseFloat(this.props.x));
      x = x / 3;
      x *= -1;
      l += x;
    }

    let t = 0;
    if (this.props.y !== null && this.props.y !== undefined) {
      let y = Math.round(parseFloat(this.props.y));
      y = y / 3;
      y *= -1;
      t += y;
    }

    return (
      <div className="plx" style={{ backgroundPosition: l + "% " + t + "%" }} />
    );
  }
}

export default ParallaxBackDrop;
