import React, { Component } from "react";

/* Screen Contents */
import LandingScreen from "./screen_landing";

class AppBody extends Component {
  Route(ui, player) {
    switch (ui.content) {
      case "":
        return (
          <LandingScreen player={player} ui={ui} onNavTo={this.props.onNavTo} />
        );
      case "#":
        return (
          <LandingScreen player={player} ui={ui} onNavTo={this.props.onNavTo} />
        );
      default:
        return (
          <LandingScreen player={player} ui={ui} onNavTo={this.props.onNavTo} />
        );
    }
  }

  render() {
    return (
      <div className="appBody">
        {this.Route(this.props.ui, this.props.player)}
      </div>
    );
  }
}

export default AppBody;
