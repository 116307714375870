import React, { Component } from "react";

class QuestionBox extends Component {
  componentDidMount = () => {
    this.setState({
      qContent: {
        title: this.props.title || "",
        qTitle: this.props.qTitle || "",
        qText: this.props.qText || "",
        qOptions: this.props.qOptions || []
      }
    });
  };

  state = {
    qContent: {
      title: "",
      qTitle: "",
      qText: "",
      qOptions: [""]
    }
  };
  render() {
    return (
      <div
        className={"card shadow " + this.props.entryAnim || ""}
        style={{
          width: "75%",
          maxWidth: "500px",
          maxHeight: "75%",
          margin: "2.5vh auto"
        }}
      >
        <h4 className="card-header">{this.state.qContent.title}</h4>
        <div className="card-body">
          <h5 className="card-title">{this.state.qContent.qTitle}</h5>
          <p className="card-text">{this.state.qContent.qText}</p>
        </div>
        <div
          className="btn-group btn-group-lg"
          role="group"
          style={{ margin: "2vh" }}
        >
          {this.state.qContent.qOptions.map((option, index) => {
            return (
              <a
                key={index}
                href="/#"
                className="btn btn-primary"
                style={{ width: "50%" }}
              >
                {option}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default QuestionBox;
