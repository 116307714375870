import React, { Component } from "react";

class HeaderBar extends Component {
  render() {
    return (
      <div className={"headerBar bg-primary " + this.props.ui.theme}>
        <div className="headerBarTitle">{this.props.ui.headerTitle}</div>
        <div className="headerBarSubTitle">{this.props.ui.headerSubTitle}</div>
      </div>
    );
  }
}

export default HeaderBar;
