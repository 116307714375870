import $ from "jquery";
var customHistory = [];

$(window).ready(function() {
  window.addEventListener("beforeunload", function(event) {
    event.preventDefault();
    event.returnValue = "o/";
    window.history.pushState(customHistory, "landing", "#");
  });

  customHistory.push("#");
  window.history.replaceState(customHistory, "landing", "#");

  window.addEventListener(
    "popstate",
    function(event) {
      if (event.state) {
        if (customHistory.length > 1) {
          customHistory.pop();

          var prevPage = customHistory[customHistory.length - 1];
          window.history.pushState(
            customHistory,
            prevPage !== undefined ? prevPage : "",
            "#" + (prevPage !== undefined && prevPage !== "#" ? prevPage : "")
          );
        } else {
          window.history.pushState(customHistory, "landing", "#");
        }
      }
    },
    false
  );
});

function updateHistory(newContent) {
  customHistory.push(
    newContent !== undefined && newContent !== "" ? newContent : "#"
  );
  window.history.replaceState(
    customHistory,
    newContent !== undefined && newContent !== "" ? newContent : "landing",
    newContent !== undefined && newContent !== "" && newContent !== "#"
      ? "#" + newContent
      : "#"
  );
}

export { customHistory, updateHistory };
