import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
/* JQuery */
import $ from "jquery";
/* JS Helper Functions */
import { ScrollTo } from "./scripts/helpers";
/* Accelerometer / Magnetometer */
import "./motion";
//import { acc } from "./motion";
/* Custom History */
import "./history";
import { /*customHistory,*/ updateHistory } from "./history";
/* Main App Structures */
import ParallaxBackDrop from "./components/parallaxBackDrop";
import HeaderBar from "./components/headerBar";
import AppBody from "./components/appBody";
import FooterBar from "./components/footerBar";

class App extends Component {
  state = {
    UI: {
      theme: "default_theme",
      headerTitle: "• Polls About Us •",
      headerSubTitle: "Life's Most Important Questions",
      footerTitle: "",
      content: "#"
    },
    Game: {},
    Player: {},
    Name: ""
  };

  componentDidMount() {}

  componentDidUpdate = () => {
    this.handleUpdateUI(this.state.UI, this.state.Game, this.state.Player);

    this.handleUpdateName();
  };

  //#region Update UI
  handleUpdateUI = (ui, game, player) => {
    ui.content = this.updateContent();
    ui.theme = this.updateTheme(ui);
    ui.headerTitle = this.updateHeaderTitle(ui);
    ui.headerSubTitle = this.updateHeaderSubTitle(ui);
    ui.footerTitle = this.updateFooterTitle(ui);

    if (ui !== this.state.UI) {
      this.setState({ UI: ui });
    }
  };

  updateContent = () => {
    return window.location.href.split("#")[1] !== undefined
      ? window.location.href.split("#")[1]
      : "#";
  };

  updateTheme = ui => {
    return ui.theme;
  };

  updateHeaderTitle = ui => {
    switch (ui.content) {
      case "":
        return "Awesome Game Title";
      default:
        return "Awesome Game Title";
    }
  };

  updateHeaderSubTitle = ui => {
    switch (ui.content) {
      case "":
        return "Landing Screen";
      case "#":
        return "Landing Screen";
      default:
        return "No Sub Title Set";
    }
  };

  updateFooterTitle = ui => {
    return ui.footerTitle || "";
  };
  //#endregion

  //#region Navigame URL
  handleOnNavTo = newUrl => {
    updateHistory(newUrl);
  };
  //#endregion

  render() {
    const ui = this.state.UI;
    const player = this.state.Player;

    return (
      <div className="app">
        {/* <ParallaxBackDrop x={acc.x} y={acc.y} z={acc.z} /> */}
        <ParallaxBackDrop x={undefined} y={undefined} z={undefined} />
        <HeaderBar ui={ui} />
        <AppBody
          player={player}
          ui={ui}
          onNavTo={this.handleOnNavTo}
          onStartGame={this.handleStartGame}
          onJoinGame={this.handleJoinGame}
          onLeaveGame={this.handleLeaveGame}
        />
        <FooterBar ui={ui} />
      </div>
    );
  }
}

export default App;
