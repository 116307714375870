import React, { Component } from "react";

class FooterBar extends Component {
  render() {
    return (
      <div className={"footerBar bg-primary " + this.props.ui.theme}>
        <div style={{ fontSize: "3vh", lineHeight: "5vh", overflow: "hidden" }}>
          {this.props.ui.footerTitle}
        </div>
      </div>
    );
  }
}

export default FooterBar;
