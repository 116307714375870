import React, { Component } from "react";

import QuestionBox from "./questionBox";

class LandingScreen extends Component {
  componentDidUpdate = () => {
    if (this.props.player.gameId !== "") {
      this.props.onNavTo("alreadyInGame");
      return;
    }
  };

  render() {
    return (
      <div>
        <QuestionBox
          entryAnim={"slideIn_Left"}
          title="Today's Question"
          qTitle="Cats or Dogs?"
          qText="The age-old question"
          qOptions={["Cats", "Dogs"]}
          entryDelay="3s"
        />
        <QuestionBox
          entryAnim={"slideIn_Right"}
          title="Today's Question"
          qTitle="Cats or Dogs?"
          qText="The age-old question"
          qOptions={["Cats", "Dogs"]}
        />
      </div>
    );
  }
}

export default LandingScreen;
